import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _, { stubFalse } from "lodash";
import { format } from "date-fns";
import Pagination from "react-js-pagination";
// constants
import LocalStrings from "../../languages/LocalStrings";
import Loader from "../Common/Loader";
import { CustomSpinner } from "../Common/base-component";

// actions
import { fetchCodes } from "./actions";
import { setAddCodeModalDetails } from "../../actions";

// files
import RightAngle from "../../assets/img/icons/angle-right.svg";

const Events = (props) => {
  const { isLoggedIn, session, loginCodeReducer, partnerProgramReducer } =
    props;
  const [processLoading, setProcessLoading] = useState(false);
  const [pageNumber, setPagenumber] = useState(1);
  const [filterBy, setFilterBy] = useState("all");
  const [startdate, setStartdate] = useState(null);
  const [enddate, setEnddate] = useState(null);
  const Navigate = useNavigate();
  const Location = useLocation();
  const filterArr = [
    { key: "all", text: "All" },
    { key: "active", text: "Activated" },
    { key: "inactive", text: "Not Activated" },
  ];

  useEffect(() => {
    // ----- fetch calls

    props.fetchCodes(session, null, pageNumber, null, null, (res) => {});
  }, []);

  const _renderCode = () => {
    return _.map(loginCodeReducer.data, (code, key) => {
      return (
        <tr>
          <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
            {code.passcode}
          </td>

          <td className="border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400">
            {code.activatedon}
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-4 pr-8 text-slate-500 dark:text-slate-400">
            {code.instructor == "1" ? "Yes" : "No"}
          </td>
          <td className="border-b border-slate-100 dark:border-slate-700 p-4 text-slate-500 dark:text-slate-400">
            {code.createdon}
          </td>
        </tr>
      );
    });
  };

  const openAddCodeModal = () => {
    //console.log("partnerProgramReducer", partnerProgramReducer);
    props.setAddCodeModalDetails({
      show: true,
      calledFrom: "passcode",
    });
  };

  const filterCode = (e) => {
    let filter = e.target.value;
    setFilterBy(filter);
    setPagenumber(1);
    props.fetchCodes(session, filter, 1, null, null, (res) => {});
  };

  const getAllData = () => {
    setProcessLoading(true);
    props.fetchCodes(
      session,
      filterBy,
      pageNumber,
      startdate,
      enddate,
      (res) => {
        setProcessLoading(false);
        if (res.success == 1) {
          downloadSample("csv", res.data);
        }
      }
    );
  };

  const downloadSample = (type, data) => {
    let XLSX = require("xlsx");
    let sample = [];

    // loop for the data
    data.map((object, index) => {
      let obj = {};
      obj.code = object.passcode;
      obj.activated = object.activatedon;
      obj.createdon = object.createdon;
      sample.push(obj);
    });

    //console.log("sample updated", sample);
    /* Create a new empty workbook, then add the worksheet */
    let wb = XLSX.utils.book_new();
    // console.log("wb", wb);

    /* Create a worksheet */
    var ws = XLSX.utils.json_to_sheet(sample);
    var sheetName = "passcode";

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /* Generate xlsx files */
    if (type === "csv") {
      XLSX.writeFile(wb, "passcode.csv");
    } else {
      XLSX.writeFile(wb, "passcode.xlsx");
    }
  };

  const paginationCallback = (pageNumber) => {
    //console.log("pageNumber", pageNumber);
    setPagenumber(pageNumber);
    props.fetchCodes(
      session,
      filterBy,
      pageNumber,
      startdate,
      enddate,
      (res) => {}
    );
  };

  const handleDates = (event) => {
    let stdate = document.getElementById("startdate").value;
    let enddate = document.getElementById("enddate").value;

    if (_.size(stdate) === 10 && _.size(enddate) === 10) {
      let stdate_replaced = _.replace(stdate, "-", "");
      let enddate_replaced = _.replace(enddate, "-", "");

      stdate_replaced = _.replace(stdate_replaced, "-", "");
      enddate_replaced = _.replace(enddate_replaced, "-", "");

      if (parseInt(stdate_replaced) > parseInt(enddate_replaced)) {
        //Toastify("error", "Invalid Date range");
      } else {
        setStartdate(stdate_replaced);
        setEnddate(enddate_replaced);
      }
    } else if (_.size(stdate) === 0 && _.size(enddate) === 0) {
      setStartdate(null);
      setEnddate(null);
    } else {
    }
  };

  if (!isLoggedIn) Navigate("/login");
  //console.log("loginCodeReducer", loginCodeReducer);
  let options = [];
  if (filterArr.length > 0) {
    filterArr.map((object, index) => {
      let children = (
        <option
          value={object.key}
          //selected={session.programid == object.key ? "selected" : ""}
        >
          {object.text}
        </option>
      );
      options.push(children);
    });
  }

  return (
    <>
      <article className="container px-6 mx-auto grid">
        {/* ----- page heading ----- */}
        {/* <h2 className="my-6 text-2xl font-semibold text-gray-700">
          {LocalStrings.navbar_events}
        </h2> */}

        {/* ----- body ----- */}
        <div className="grid grid-cols-4 mt-6">
          {/* ----- ongoing upcoming section ----- */}
          <div className="col-span-4">
            <div className=" flex justify-between">
              <div>
                <h4 className="mb-4 text-lg font-bold text-gray-600">
                  {LocalStrings.label_login_codes}
                </h4>
              </div>
              <div>
                <a
                  onClick={() => openAddCodeModal()}
                  className=" cursor-pointer text-green-1000 hover:underline"
                >
                  {LocalStrings.label_create_login_code}
                </a>
              </div>
            </div>
            <div>
              <div className="flex justify-between gap-4 lg:gap-0">
                <div>
                  {loginCodeReducer.hasOwnProperty("issued") ? (
                    <div className=" flex justify-between">
                      <div className="flex items-center">
                        <div>
                          <h4 className="text-lg font-semibold text-gray-600">
                            {LocalStrings.label_login_codes_issued_count}{" "}
                            :&nbsp;
                          </h4>
                        </div>
                        <div className="text-lg font-semibold">
                          {loginCodeReducer.issued}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {loginCodeReducer.hasOwnProperty("activated") ? (
                    <div className=" flex justify-between">
                      <div className="flex items-center">
                        <div>
                          <h4 className=" text-lg font-semibold text-gray-600">
                            {LocalStrings.label_login_codes_activated_count}{" "}
                            :&nbsp;
                          </h4>
                        </div>
                        <div className="text-lg font-semibold">
                          {loginCodeReducer.activated}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex justify-center items-center gap-5">
                  <div className="flex justify-center items-center gap-2">
                    <div>Filter by</div>
                    <div>
                      <select
                        className="text-green-1000"
                        onChange={(e) => filterCode(e)}
                      >
                        {options}
                      </select>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div>Start date:- </div>
                    <div>
                      <input
                        className="appearance-none  px-3 py-2 border border-ternary rounded-md shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight sm:text-sm w-40"
                        type="date"
                        id="startdate"
                        name="startdate"
                        onChange={(e) => handleDates(e)}
                        placeholder="Start Date"
                      />
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <div>End date:- </div>
                    <div>
                      <input
                        className="appearance-none  px-3 py-2 border border-ternary rounded-md shadow-sm placeholder-ternary focus:outline-none focus:ring-primary focus:border-primaryLight sm:text-sm w-40"
                        type="date"
                        id="enddate"
                        name="enddate"
                        onChange={(e) => handleDates(e)}
                        placeholder="End Date"
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-green-1000 text-white active:bg-green-1000 hover:bg-green-1001 focus:outline-none"
                      onClick={() => getAllData()}
                    >
                      {processLoading ? (
                        <CustomSpinner />
                      ) : (
                        <span>Export Codes</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 -mb-3">
              {loginCodeReducer.data ? (
                loginCodeReducer.data.length > 0 ? (
                  <>
                    <div className="not-prose relative bg-slate-100 rounded-xl overflow-hidden dark:bg-slate-800/25">
                      <div
                        style={{ backgroundPosition: "10px 10px" }}
                        className="absolute inset-0 bg-grid-slate-100 [mask-image:linear-gradient(0deg,#fff,rgba(255,255,255,0.6))] dark:bg-grid-slate-700/25 dark:[mask-image:linear-gradient(0deg,rgba(255,255,255,0.1),rgba(255,255,255,0.5))]"
                      ></div>
                      <div className="relative rounded-xl overflow-auto">
                        <div className="shadow-sm overflow-hidden my-8">
                          <table className="border-collapse table-fixed w-full text-sm">
                            <thead>
                              <tr>
                                <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                  CODE
                                </th>

                                <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                  Activated Date
                                </th>
                                <th className="border-b dark:border-slate-600 font-medium p-4 pr-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                  Code for Instructor
                                </th>
                                <th className="border-b dark:border-slate-600 font-medium p-4 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
                                  Created Date
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white dark:bg-slate-800">
                              {_renderCode()}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="absolute inset-0 pointer-events-none border border-black/5 rounded-xl dark:border-white/5"></div>
                    </div>
                    <div className="mt-4">
                      <Pagination
                        activePage={pageNumber}
                        itemsCountPerPage={30}
                        totalItemsCount={loginCodeReducer.total}
                        pageRangeDisplayed={10}
                        onChange={(e) => paginationCallback(e)}
                      />
                    </div>
                  </>
                ) : (
                  <div className="flex items-center justify-center text-base ">
                    No Code Found
                  </div>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    loginCodeReducer: state.loginCodeReducer,
    partnerProgramReducer: state.partnerProgramReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCodes,
      setAddCodeModalDetails,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Events);
