import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment-timezone";
// constants
import LocalStrings from "../../languages/LocalStrings";
import Loader from "../Common/Loader";
import { salesDateMonthsList } from "../Common/constant";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// actions
import { fetchData } from "./actions";

// files
import LeftAngle from "../../assets/img/icons/arrow-left.svg";
import RightAngle from "../../assets/img/icons/arrow-right.svg";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Graphview = (props) => {
  const { isLoggedIn, session, dashboardActivity } = props;
  const Navigate = useNavigate();
  const Location = useLocation();
  const [currentDateTab, setCurrentDateTab] = useState(0);
  const [dateIndex, setDateIndex] = useState(0);
  const [dateNameToHidden, setDateNameToHidden] = useState("Today");
  const [dateNameToShow, setDateNameToShow] = useState("Today");
  const [dailyCurrentSelection, setDailyCurrentSelection] = useState(
    moment().format("Do MMM YYYY")
  );
  const [activeTab, setActiveTab] = useState("time_activity");
  const [tabArr, setTabArr] = useState([
    { key: "time_activity", text: "Activity Time" },
    { key: "bonus_activity", text: "Bonus Activity" },
  ]);

  const changeTab = (tab) => {
    setActiveTab(tab);
  };

  if (!isLoggedIn) Navigate("/login");

  return (
    <>
      <article className="container px-6 mx-auto grid">
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-4 my-8">
          {_.map(tabArr, (obj, key) => {
            return (
              <div
                className={`text-center p-3 text-sm font-semibold border border-green-1000 ${
                  activeTab === obj.key
                    ? "bg-green-1000 text-white"
                    : "text-green-1000 bg-white  hover:bg-green-1001 hover:text-white cursor-pointer"
                }  transition-colors duration-150  rounded-md`}
                onClick={() => changeTab(obj.key)}
              >
                {obj.text}
              </div>
            );
          })}
        </div>
        {activeTab === "time_activity" ? (
        <div className=" h-60 overflow-auto">
          <div>
            This graph shows the total activity time spent in the app per day. It distinguishes between the activity time spent by users who completed their daily task (COMPLETE) and the activity time spent by users who failed to complete their tasks (PARTIAL).
          </div>
         
            <div className="pb-5">
                <div className="mt-3 font-semibold">The graph has 4 filters:</div>
                <ul className=" list-disc px-4">
                  <li>Program Name: on this filter, the program being analyzed can be selected. Please note the filter at the top of the Partner Panel’s header will not work on this graph.</li>
                  <li>Passcode: on this filter, one or several passcodes can be filtered, if one wishes to track a specific user.
                  </li>
                  <li>Date Interval: on this filter, the time period being considered can be set. It shows the last 30 days per default .
                  </li>
                  <li>IsTestUser: this filter should be put to FALSE, when wanting to exclude all test users for accurate reporting.
                  </li>
                </ul>
                <div className="mt-3 font-semibold">Concerning the data:</div>
                <ul className=" list-disc px-4">
                  <li>
                    Activity time is measured from when the user goes into the app until they leave it. If, while following an audio session, the phone goes into sleep mode and exits the app, and the user does not go back into the app at the end of the session, then the end of the audio recording is used as the end of this specific activity.
                  </li>
                  <li>
                    For each user, the days are recorded using their specific timezone, as their task calendar follows their time zone. As a result, activities from yesterday can keep occurring today, if the users are in a timezone further wes
                  </li>
                </ul>
            </div>
        </div>
         ):(
          <div className=" h-60 overflow-auto">
            <div>
            This graph shows the Bonus activity per day. It displays the amount of users doing at least one bonus activity, as well as the number of bonuses activities done. The same user can do several bonus activities per day.
            </div>
            <div className="pb-5">
              <div className="mt-3 font-semibold">The graph has 4 filters:</div>
              <ul className=" list-disc px-4">
                <li>Program Name: on this filter, the program being analyzed can be selected. Please note the filter at the top of the Partner Panel’s header will not work on this graph.</li>
                <li>
                  Passcode: on this filter, one or several passcodes can be filtered, if one wishes to track a specific user.
                </li>
                <li>
                   Date Interval:  on this filter, the time period being considered can be set. It shows the last 30 days per default.
                </li>
                <li>
                IsTestUser: this filter should be put to FALSE, when wanting to exclude all test users for accurate reporting.
                </li>
              </ul>
              <div className="mt-3 font-semibold">Concerning the data:</div>
              <ul className=" list-disc px-4">
                <li>
                For each user, the days are recorded using their specific timezone, as their task calendar follows their time zone. As a result activities from yesterday can keep occurring today, if the users are in a timezone further west.
                </li>
               
              </ul>
            </div>
          </div>
        )}
        <div className="flex justify-center items-center">
          {activeTab === "time_activity" ? (
            <iframe
              width="100%"
              height="750"
              src="https://lookerstudio.google.com/embed/reporting/b40ec2ce-2bcf-4bc0-8402-d55a7b6f967b/page/TC3hD"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen
            ></iframe>
          ) : (
            <iframe
              width="100%"
              height="750"
              src="https://lookerstudio.google.com/embed/reporting/35a3eee7-df92-41a3-98b4-24c9c72eeac2/page/TC3hD"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen
            ></iframe>
          )}
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    dashboardActivity: state.dashboardActivity,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchData }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Graphview);
