import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";
import { CustomSpinner } from "../../Common/base-component";
// constants
import LocalStrings from "../../../languages/LocalStrings";
import Loader from "../../Common/Loader";
import { Toastify } from "../../Common/Toastify";
// actions
import { fetchSingleUser, downloadData, singleUserEmpty } from "../actions";

// files
import RightAngle from "../../../assets/img/icons/angle-right.svg";
import { Button } from "reactstrap";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const SingleUser = (props) => {
  const { isLoggedIn, session, userProgress } = props;
  const [processLoading, setProcessLoading] = useState(false);
  const [process1Loading, setProcess1Loading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [partnerCode, setPartnerCode] = useState("");
  const [activeTab, setActiveTab] = useState("info_text");
  const [tabArr, setTabArr] = useState([]);
  const Navigate = useNavigate();
  const Location = useLocation();
  const params = useParams();

  useEffect(() => {
    props.singleUserEmpty();
    let passcode = params?.passcode;
    //console.log("passcode", passcode);
    if (passcode !== undefined && passcode != "") {
      setPartnerCode(passcode);
      searchUser(passcode);
    }
    if (session.subdomain && session.subdomain == "loreal") {
      let arrtab = [
        { key: "info_text", text: "Info text" },
        { key: "session", text: "Sessions" },
        { key: "app_notification", text: "App Notification" },
        { key: "push_notification", text: "Push Notification" },
        { key: "reward", text: "Reward" },
        { key: "rating", text: "Rating" },
      ];
      setTabArr(arrtab);
    } else {
      let arrtab = [
        { key: "info_text", text: "Info text" },
        { key: "session", text: "Sessions" },
        { key: "app_notification", text: "App Notification" },
        { key: "push_notification", text: "Push Notification" },
        { key: "reward", text: "Reward" },
        { key: "rating", text: "Rating" },
        { key: "diary", text: "Diary" },
        { key: "photo", text: "Photo" },
        { key: "quiz", text: "Quiz" },
      ];
      setTabArr(arrtab);
    }
    //console.log("session", session);
  }, []);

  const _renderRating = (ratingArr, userRating) => {
    //console.log("ratingArr", ratingArr);
    //console.log("userRating", userRating);
    return _.map(ratingArr, (obj, key) => {
      let matchData = _.filter(userRating, (row) => {
        return row.ratingid == obj.id;
      });
      let rating = matchData.length > 0 ? matchData[0].rating : 0;
      let reason = matchData.length > 0 ? matchData[0].reason : "";
      let metaData = _.filter(obj.meta, (data) => {
        return data.rating == rating;
      });
      //console.log("matchData", matchData);
      return (
        <div className="p-4">
          <div className="text-slate-500 dark:text-slate-400 mb-3">
            {obj.title}
          </div>
          <div className="flex items-center gap-4">
            <div className="flex gap-1.5">
              {rating > 0
                ? _.times(rating, (i) => {
                    return (
                      <div class="rounded-full w-7 h-7 bg-green-1000">
                        &nbsp;
                      </div>
                    );
                  })
                : ""}
              {rating > 0
                ? _.times(5 - rating, (i) => {
                    return (
                      <div class="rounded-full w-7 h-7 bg-white border-2 border-green-1000">
                        &nbsp;
                      </div>
                    );
                  })
                : ""}
            </div>
            <div className="text-green-1000">
              {metaData.length > 0 ? metaData[0].value : ""}
            </div>
          </div>
          {metaData.length > 0 && metaData[0].reason ? (
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-7 gap-1 mt-3">
              {_.map(metaData[0].reason.split(","), (res, index) => {
                return (
                  <div
                    className={classNames(
                      res.trim() == reason.trim()
                        ? "bg-green-1000 text-white"
                        : "text-black bg-gray-200",
                      "p-3 text-sm text-center rounded-full"
                    )}
                  >
                    {res}
                  </div>
                );
              })}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    });
  };

  const _renderContent = () => {
    let matchData = _.filter(userProgress.data, (obj) => {
      return obj.key == activeTab;
    });
    //console.log("matchData", matchData);
    return matchData.length > 0 ? (
      _.map(matchData, (obj, key) => {
        return obj.key == "rating" && obj.completed == 1 ? (
          obj.ratingdetails.length > 0 && obj.user_rating.length > 0 ? (
            <div className="divide-y">
              {_renderRating(obj.ratingdetails, obj.user_rating)}
            </div>
          ) : (
            ""
          )
        ) : (
          <div className="flex text-sm justify-between">
            <div className="w-1/5 p-4 text-slate-500 dark:text-slate-400 text-left">
              Week {obj.week}
            </div>
            <div className="w-1/5 p-4 text-slate-500 dark:text-slate-400 text-left">
              {obj.program}
            </div>
            <div className="w-1/5 p-4 text-slate-500 dark:text-slate-400 text-left">
              {obj.title}
            </div>
            <div className="w-1/5 p-4  text-slate-500 dark:text-slate-400 text-left">
              {obj.createdon}
            </div>
            <div className="w-1/5 p-4  text-slate-500 dark:text-slate-400 text-left">
              {obj.sessionname}
            </div>
          </div>
        );
      })
    ) : (
      <div className="text-center text-base">No activities recorded</div>
    );
  };

  const changeTab = (tab) => {
    setActiveTab(tab);
    _renderContent();
  };

  const searchUser = (passcode = null) => {
    console.log("partnerCode", partnerCode);
    console.log("passcode", passcode);
    if (partnerCode != "" || passcode != null) {
      let values = {};
      values.partnercode = passcode ? passcode : partnerCode;
      values.partnerid = session.id;
      setSearchLoading(true);
      props.fetchSingleUser(session, values, (res) => {
        setSearchLoading(false);
        if (res.success == 1) {
        } else {
          Toastify("error", res.message);
        }
      });
    }
  };

  const codeSet = (e) => {
    let code = e.target.value;
    if (code != "") {
      setPartnerCode(code);
    } else {
      props.singleUserEmpty();
    }
  };

  const getSpecificData = (section) => {
    let values = {};
    values.partnerid = session.id;
    values.keytype = section;
    values.partnercode = partnerCode;
    // console.log("values", values);
    // debugger;
    if (section == "all") {
      setProcess1Loading(true);
    } else {
      setProcessLoading(true);
    }

    props.downloadData(session, values, (res) => {
      //console.log("res", res);
      if (section == "all") {
        setProcess1Loading(false);
      } else {
        setProcessLoading(false);
      }
      if (res.success == 1) {
        downloadSample(values.format, res.data);
      }
    });
  };

  const downloadSample = (type, data) => {
    let XLSX = require("xlsx");
    let sample = [];
    data.map((object, index) => {
      if (
        object.key == "rating" &&
        object.ratingdetails &&
        object.ratingdetails.length > 0 &&
        object.user_rating &&
        object.user_rating.length > 0
      ) {
        object.ratingdetails.map((ratobj, row) => {
          let matchData = _.filter(object.user_rating, (data) => {
            return data.ratingid == ratobj.id;
          });
          let givenrating = matchData.length > 0 ? matchData[0].rating : 0;
          let reason = matchData.length > 0 ? matchData[0].reason : "";
          let metaData = _.filter(ratobj.meta, (data) => {
            return data.rating == givenrating;
          });

          let obj = {};
          obj.Week = object.week;
          obj.Program = object.program;
          obj.Section = _.startCase(object.key);
          obj.Title = ratobj.title;
          obj.Rating = givenrating;
          obj.Comment = metaData.length > 0 ? metaData[0].value : "";

          obj.Reason =
            metaData.length > 0 && metaData[0].reason && reason != ""
              ? reason
              : "";
          obj.Date = object.createdon;
          sample.push(obj);
        });
      } else if (object.key == "reward" && object.rewarddetails) {
        let obj = {};
        obj.Week = object.week;
        obj.Program = object.program;
        obj.Section = _.startCase(object.key);
        obj.Title = object.rewarddetails.title;
        obj.Date = object.createdon;
        sample.push(obj);
      } else {
        let obj = {};
        obj.Week = object.week;
        obj.Program = object.program;
        obj.Section = _.startCase(object.key);
        if (object.key == "session" && object.sessionname) {
          obj.SessionName = object.sessionname;
        }
        obj.Date = object.createdon;
        sample.push(obj);
      }
    });

    //console.log("sample updated", sample);
    /* Create a new empty workbook, then add the worksheet */
    let wb = XLSX.utils.book_new();
    // console.log("wb", wb);

    /* Create a worksheet */
    var ws = XLSX.utils.json_to_sheet(sample);
    var sheetName = "user_progress";

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /* Generate xlsx files */
    if (type === "csv") {
      XLSX.writeFile(wb, "user_progress.csv");
    } else {
      XLSX.writeFile(wb, "user_progress.xlsx");
    }
  };

  if (!isLoggedIn) Navigate("/login");
  //console.log("userProgress", userProgress);
  return (
    <>
      <article className="container px-6 mx-auto grid">
        {/* ----- page heading ----- */}
        {/* <h2 className="my-6 text-2xl font-semibold text-gray-700">
          {LocalStrings.navbar_events}
        </h2> */}

        {/* ----- body ----- */}
        <div className="grid grid-cols-4 mt-6">
          {/* ----- ongoing upcoming section ----- */}
          <div className="col-span-4">
            <div className=" flex justify-between">
              <div>
                <h4 className="mb-4 text-lg font-bold text-gray-600">
                  {LocalStrings.label_single_user}
                </h4>
              </div>
              <div></div>
            </div>

            <div className="mt-4 -mb-3">
              <div>
                <div className="text-sm">Enter User code to view data</div>
                <div className="flex items-center">
                  <div>
                    <input
                      type="text"
                      name="passcode"
                      value={partnerCode != "" ? partnerCode : ""}
                      className="block w-full mt-1 text-sm rounded-sm border-secondary3 focus:ring-primary focus:border-primary focus:shadow-outline-blue focus:outline-none form-input"
                      onChange={(e) => codeSet(e)}
                    />
                  </div>
                  <div className="pl-3">
                    <button
                      type="button"
                      className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 bg-green-1000 text-white active:bg-green-1000 hover:bg-green-1001 border border-transparent rounded-lg  focus:outline-none"
                      onClick={() => searchUser()}
                    >
                      {searchLoading ? <CustomSpinner /> : <span>Search</span>}
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                {userProgress && userProgress.data ? (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-4">
                      {_.map(tabArr, (obj, key) => {
                        return (
                          <div
                            className={`text-center p-3 text-sm font-semibold border border-green-1000 ${
                              activeTab === obj.key
                                ? "bg-green-1000 text-white"
                                : "text-green-1000 bg-white  hover:bg-green-1001 hover:text-white cursor-pointer"
                            }  transition-colors duration-150  rounded-md`}
                            onClick={() => changeTab(obj.key)}
                          >
                            {obj.text}
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      {userProgress.data.length > 0 ? (
                        <>
                          <div className="bg-slate-100 w-full rounded min-h-fit mt-6 p-5 h-80 overflow-auto">
                            <div className="text-center text-base font-semibold">
                              {_.startCase(activeTab)}
                            </div>
                            {_renderContent()}
                          </div>
                          <div className="flex justify-center mt-3 gap-5">
                            <div>
                              <button
                                type="button"
                                className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150  border border-transparent rounded-lg bg-green-1000 text-white active:bg-green-1000 hover:bg-green-1001 focus:outline-none"
                                onClick={() => getSpecificData(activeTab)}
                              >
                                {processLoading ? (
                                  <CustomSpinner />
                                ) : (
                                  <span>
                                    Export {_.startCase(activeTab)} Summary
                                  </span>
                                )}
                              </button>
                            </div>
                            <div>
                              <button
                                type="button"
                                className="px-5 py-2 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-green-1000 text-white active:bg-green-1000 hover:bg-green-1001 focus:outline-none"
                                onClick={() => getSpecificData("all")}
                              >
                                {process1Loading ? (
                                  <CustomSpinner />
                                ) : (
                                  <span>Export All Sections Summary</span>
                                )}
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    userProgress: state.userProgress,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSingleUser,
      downloadData,
      singleUserEmpty,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SingleUser);
