import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";

// constants
import LocalStrings from "../../languages/LocalStrings";
import Loader from "../Common/Loader";

// actions

// files
import RightAngle from "../../assets/img/icons/angle-right.svg";

const ActiveUsers = (props) => {
  const { isLoggedIn, session, partnerUserList, partnerProgramReducer } = props;
  const Navigate = useNavigate();
  const Location = useLocation();

  useEffect(() => {
    // ----- fetch calls
  }, []);

  if (!isLoggedIn) Navigate("/login");
  //console.log("partnerUserList", partnerUserList);
  return (
    <>
      <article className="container px-6 mx-auto grid">
        <div className="grid grid-cols-4 mt-6">
          <div className="col-span-4">
            <div className="pb-5">
              <div>
               This table lists the users who have been completing more than a single day on any given calendar date in the period being displayed. For each of these users, it displays how many therapy days they did complete on that single day.
              </div>
              <div className="">
                <div className="mt-3 font-semibold">This table has 3 filters:</div>
                <ul className=" list-disc px-4">
                  <li>Date Interval:  on this filter, the time period being considered can be set. It shows the last 30 days per default.</li>
                  <li>Program Name: on this filter, the program being analyzed can be selected. Please note the filter at the top of the Partner Panel’s header will not work on this graph.</li>
                  <li>IsTestUser: This filter should be put to FALSE, when wanting to exclude all test users for accurate reporting.</li>
                </ul>
              </div>
            </div>
            <div className=" flex justify-center">
              <iframe
                width="100%"
                height="750"
                src="https://lookerstudio.google.com/embed/reporting/04a2c070-ea97-49aa-8cd3-4f207c2d8e58/page/4miiD"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ActiveUsers);
