const IcomoonIcons = {
  reelsIcon: "\ue900",
  videoPlayIcon: "\ue901",
  uploadIcon: "\ue902",
  charityIcon: "\ue903",
  eventIcon: "\ue904",
  bellIcon: "\ue905",
  userIcon: "\ue90b",
  multiUserIcon: "\ue909",
  barchart1Icon: "\ue906",
  barchart2Icon: "\ue908",
  piechartIcon: "\ue90a",
  downlaodIcon: "\ue907",
  ticketIcon: "\ue90c",
  emailIcon: "\ue90d",
};

export default IcomoonIcons;
