import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import _ from "lodash";
import { format } from "date-fns";
// constants
import LocalStrings from "../../../languages/LocalStrings";
import Loader from "../../Common/Loader";
import { Toastify } from "../../Common/Toastify";
// files
import RightAngle from "../../../assets/img/icons/angle-right.svg";
import { Button } from "reactstrap";
import {
  renderInputField,
  renderInputFieldTextArea,
  CustomSpinner,
} from "../../Common/base-component";
import { createEmailAlert, fetchEmailAlertData } from "../actions";
import { data } from "autoprefixer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const EmailAlert = (props) => {
  const { isLoggedIn, session, handleSubmit } = props;
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const Location = useLocation();
  useEffect(() => {
    props.fetchEmailAlertData(session);
  }, []);

  const onSubmitForm = (values) => {
    console.log("values", values);
    setLoading(true);
    props.createEmailAlert(session, values, (res) => {
      setLoading(false);
      if (res.success == 1) {
        Toastify("success", res.message);
      } else Toastify("error", res.message);
    });
  };

  if (!isLoggedIn) Navigate("/login");

  return (
    <>
      <article className="container px-6 mx-auto">
        <div className="flex items-center justify-center">
          <div>
            <div className="my-8">
              <div className=" flex justify-between">
                <div>
                  <h4 className="mb-4 text-lg font-bold text-gray-600">
                    Email Alerts
                  </h4>
                </div>
              </div>
            </div>
            <div>
              Enter email adresses, comma separated that need to be alerted
              about superactive and dormant users.
              <br />
              Email send weekly
            </div>
            <div>
              <form className="" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="my-4">
                  <Field
                    name="subject"
                    label="Subject"
                    component={renderInputField}
                    placeholder="Enter Subject in email"
                    mandatory
                  />
                </div>
                <div className="my-4">
                  <Field
                    name="email"
                    placeholder="Enter email addresses"
                    label="Email"
                    component={renderInputFieldTextArea}
                    mandatory
                  />
                </div>
                <div className="flex flex-col items-center justify-center px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50">
                  <button
                    type="submit"
                    className="px-5 py-3 min-w-update-btn font-medium leading-5 transition-colors duration-150  border border-transparent rounded-lg bg-green-1000 text-white active:bg-green-1000 hover:bg-green-1001 focus:outline-none"
                  >
                    {loading ? <CustomSpinner /> : <span>Submit</span>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  // ----- initial value for redux form
  let initial = {};
  if (state.emailAlertData) {
    initial.subject = state.emailAlertData.subject;
    initial.email = state.emailAlertData.emails;
  }

  // ----- setting initial value

  return {
    session: state.session,
    initialValues: initial,
    isLoggedIn: state.isLoggedIn,
    emailAlertData: data.emailAlertData,
  };
};

const validate = (values, ownProps) => {
  let errors = {};

  if (!values.subject) {
    errors.subject = LocalStrings.validation_required;
  }
  if (!values.email) {
    errors.email = LocalStrings.validation_required;
  } else {
    var emails = values.email.split(",");
    var regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var valid = true;
    for (var i = 0; i < emails.length; i++) {
      if (emails[i] != "" && !regex.test(emails[i])) {
        valid = false;
      }
    }
    if (valid == false) {
      errors.email = LocalStrings.invalid_email;
    }
  }

  return errors;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ createEmailAlert, fetchEmailAlertData }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    validate,
    form: "EmailAlertForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: false,
  })(EmailAlert)
);
