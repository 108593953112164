import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { Field, reduxForm } from "redux-form";
import { format } from "date-fns";
// constants
import LocalStrings from "../../../languages/LocalStrings";
import Loader from "../../Common/Loader";
import {
  renderRadio,
  renderSelect,
  CustomSpinner,
} from "../../Common/base-component";
// actions
import { downloadData, fetchUsers } from "../actions";
import XLSX from "xlsx";

// files
import RightAngle from "../../../assets/img/icons/angle-right.svg";
import { Button } from "reactstrap";
import { Toastify } from "../../Common/Toastify";

const DownloadData = (props) => {
  const { isLoggedIn, session, userProgress, handleSubmit, partnerUserList } =
    props;
  const [processLoading, setProcessLoading] = useState(false);
  const [partnerCode, setPartnerCode] = useState(null);
  const [userData, setUserData] = useState([]);
  const Navigate = useNavigate();
  const Location = useLocation();

  useEffect(() => {
    // props.fetchUsers(session, (res) => {
    //   if (res.data && res.data.length > 0) {
    //     let options = [];
    //     res.data.map((object, index) => {
    //       let obj = {};
    //       if (object.userid) {
    //         obj.value = object.userid;
    //         obj.text = object.firstname;
    //         options.push(obj);
    //       }
    //     });
    //     setUserData(options);
    //   }
    // });
  }, []);

  const onSubmitForm = (values) => {
    console.log("values", values);
    values.partnerid = session.id;
    values.keytype = "all";
    console.log("values", values);
    debugger;
    setProcessLoading(true);
    props.downloadData(session, values, (res) => {
      console.log("res", res);
      setProcessLoading(false);
      if (res.success == 1) {
        downloadSample(values.format, res.data);
      } else {
      }
    });
  };

  const downloadSample = (type, data) => {
    let XLSX = require("xlsx");
    let sample = [];
    data.map((object, index) => {
      sample.push(object);
    });

    //console.log("sample updated", sample);
    /* Create a new empty workbook, then add the worksheet */
    let wb = XLSX.utils.book_new();
    // console.log("wb", wb);

    /* Create a worksheet */
    var ws = XLSX.utils.json_to_sheet(sample);
    var sheetName = "user_progress";

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    /* Generate xlsx files */
    if (type === "csv") {
      XLSX.writeFile(wb, "user_progress.csv");
    } else {
      XLSX.writeFile(wb, "user_progress.xlsx");
    }
  };

  if (!isLoggedIn) Navigate("/login");
  //console.log("userProgress", userProgress);

  // console.log("options", options);
  return (
    <>
      <article className="container px-6 mx-auto grid">
        {/* ----- page heading ----- */}
        {/* <h2 className="my-6 text-2xl font-semibold text-gray-700">
          {LocalStrings.navbar_events}
        </h2> */}

        {/* ----- body ----- */}
        <div className="grid grid-cols-4 mt-6">
          {/* ----- ongoing upcoming section ----- */}
          <div className="col-span-2">
            <div className=" flex justify-between">
              <div>
                <h4 className="mb-4 text-lg font-bold text-gray-600">
                  {LocalStrings.label_download_data}
                </h4>
              </div>
              <div></div>
            </div>

            <div className="mt-4 -mb-3">
              <form className="" onSubmit={handleSubmit(onSubmitForm)}>
                <div className="grid">
                  <div>
                    <span className="text-gray-700 dark:text-gray-400 text-sm">
                      Choose Format<span className="text-red-600">*</span>
                    </span>
                  </div>
                  <div className="flex items-center gap-3 mb-5">
                    <Field
                      name="format"
                      label="Excel"
                      component={renderRadio}
                      type="radio"
                      value="xl"
                      placeholder={LocalStrings.placeholder_team_name}
                    />
                    <div className="pl-3">
                      <Field
                        name="format"
                        label="CSV"
                        value="csv"
                        component={renderRadio}
                        type="radio"
                        placeholder={LocalStrings.placeholder_team_name}
                      />
                    </div>
                  </div>
                  {/* <Field
                    name="userid"
                    label={"Choose User"}
                    placeholder={"Select User"}
                    component={renderSelect}
                    opts={userData}
                    mandatory
                  /> */}
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="px-5 py-3 min-w-update-btn font-medium leading-5 transition-colors duration-150 border border-transparent rounded-lg bg-green-1000 text-white active:bg-green-1000 hover:bg-green-1001 focus:outline-none"
                    >
                      {processLoading ? (
                        <CustomSpinner />
                      ) : (
                        <span>Export All Users Data</span>
                      )}
                    </button>
                  </div>
                  <div className=" text-gray-400 mt-5">
                    <div>Example:-</div>
                    <div className="text-sm">
                      Username,Week,Programm, Section, Date,Title, Rating,
                      Comment, Reason
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const validate = (values, ownProps) => {
  let errors = {};
  if (!values.format) {
    errors.format = LocalStrings.validation_required;
  }
  if (!values.userid || values.userid == "") {
    errors.userid = LocalStrings.validation_required;
  }

  return errors;
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
    userProgress: state.userProgress,
    partnerUserList: state.partnerUserList,
  };
};

export default connect(mapStateToProps, {
  downloadData,
  fetchUsers,
})(
  reduxForm({
    validate,
    form: "DownloadDataForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(DownloadData)
);
