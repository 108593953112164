import _ from "lodash";
import { INSTANCE_URL } from "../../Common/constant";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
} from "../../Common/network-call";
import { reset } from "redux-form";

export const EMAIL_ALERT = "EMAIL_ALERT";

export const createEmailAlert = (session, values, callback) => {
  let postUrl = `${INSTANCE_URL}/createemailalert`;
  values.token = session.session_token;
  values.programid = session.programid;
  values.partnerid = session.id;
  return (dispatch) => {
    postRequest(
      postUrl,
      values,
      session,
      dispatch,
      (res) => {
        if (res.code == 200) {
          if (res.msg == "success") {
            callback({ success: 1, message: res.desc });
          } else {
            callback({ success: 2, message: res.desc });
          }
        } else {
          callback({ success: 3, message: res.desc });
        }
      },
      (error) => {
        callback({ success: 0, message: error });
      }
    );
  };
};

export const fetchEmailAlertData = (session, callback) => {
  let fetchUrl = `${INSTANCE_URL}/getemailalertdata?partnerid=${session.id}&programid=${session.programid}&token=${session.session_token}`;

  return (dispatch) => {
    getRequest(
      fetchUrl,
      session,
      dispatch,
      (res) => {
        dispatch({
          type: EMAIL_ALERT,
          payload: res.data,
        });
        callback({ success: 1, data: res.data });
      },
      (error) => {
        callback({ succcess: 0, message: error });
      }
    );
  };
};
