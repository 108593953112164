import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";

// constants
import LocalStrings from "../../languages/LocalStrings";
import Loader from "../Common/Loader";

// actions

// files
import RightAngle from "../../assets/img/icons/angle-right.svg";

const Program = (props) => {
  const { isLoggedIn, session, partnerUserList, partnerProgramReducer } = props;
  const Navigate = useNavigate();
  const Location = useLocation();

  useEffect(() => {
    // ----- fetch calls
  }, []);

  if (!isLoggedIn) Navigate("/login");
  //console.log("partnerUserList", partnerUserList);
  return (
    <>
      <article className="container px-6 mx-auto grid">
        <div className="grid grid-cols-4 mt-6">
          <div className="col-span-4">
            <div className="pb-5">
              <div>
               This table lists detailed activity from the App activity log. Like all looker studio tables, its content can be exported as csv or Google Sheet by clicking on the 3 small vertical dots above the top right corner of the table, and selecting export.
              </div>
              <div className="">
                <div className="mt-3 font-semibold">This table has 5 filters:</div>
                <ul className=" list-disc px-4">
                  <li>Program Name: on this filter, the program being analyzed can be selected. Please note the filter at the top of the Partner Panel’s header will not work on this graph.</li>
                  <li>Date Interval:  on this filter, the time period being considered can be set. It shows the last 30 days per default</li>
                  <li>Passcode: on this filter, one or several passcodes can be filtered, if one wishes to track a specific user.</li>
                  <li>Type: this allows to filter on the type of events: info text reading, audio track, bonus video etc.</li>
                  <li>IsTestUser: This filter should be put to FALSE, when wanting to exclude all test users for accurate reporting.</li>
                </ul>
              </div>
            </div>
            <div className=" flex justify-center">
              <iframe
                width="100%"
                height="750"
                src="https://lookerstudio.google.com/embed/reporting/2cc13d76-038b-44bb-b355-b4cb8afda32b/page/4miiD"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Program);
