import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";

// constants
import LocalStrings from "../../languages/LocalStrings";
import Loader from "../Common/Loader";

// actions

// files
import RightAngle from "../../assets/img/icons/angle-right.svg";

const Compliance = (props) => {
  const { isLoggedIn, session, partnerUserList, partnerProgramReducer } = props;
  const Navigate = useNavigate();
  const Location = useLocation();

  useEffect(() => {
    // ----- fetch calls
  }, []);

  if (!isLoggedIn) Navigate("/login");
  //console.log("partnerUserList", partnerUserList);
  return (
    <>
      <article className="container px-6 mx-auto grid">
        <div className="grid grid-cols-4 mt-6">
          <div className="col-span-4">
            <div className=" h-60 overflow-auto">
              <div>
                <div>
                  This dashboard contains two graphs and a table
                </div>
                <div className="">
                    The first graph, Program Adherence, shows the overall user adherence to the program’s scheduled plan. <br/>This graph shows: 
                  </div>
                <div className="">
                
                  <ul className=" list-disc px-4">
                    <li>Users who have completed their daily tasks: <b>completing users</b></li>
                    <li>Users who have partially done at least one of their daily tasks: <b>partial users</b></li>
                    <li>Users who have been inactive for less than 3 days: <b>inactives less than 3 days</b></li>
                    <li>Users who have been inactive for 3 to 7 days: <b>inactives 3 to 7 days</b></li>
                    <li>Users who have been inactive for more than 7 days and assumed to have dropped out:<b> dropouts</b></li>
                  </ul>
                </div>
              </div>

              <div className="py-5">
                <div>
                  The second graph, Program Activity, shows how users are active over time. While the first graph measures against the schedule program days, this graph measures against calendar days.
                </div>
                <div className="">
                  For each calendar day, this graph shows: 
                  </div>
                <div className="">
                
                  <ul className=" list-disc px-4">
                    <li>Total users enrolled in the program: <b>Enrolled users</b></li>
                    <li>Users who have completed their daily tasks: <b>completing users</b></li>
                    <li>Users who have partially done at least one of their daily tasks: <b>partial users</b></li>
                    <li>Users who have been inactive for a while, but resumed later some activities:  <b>inactives momentarily</b></li>
                    <li>Users who have been inactive for less than 3 days: <b>inactives less than 3 days</b></li>
                    <li>Users who have been inactive for 3 to 7 days: <b>inactives 3 to 7 days</b></li>
                    <li>Users who have been inactive for more than 7 days and assumed to have dropped out: <b>dropouts</b></li>
                    <li>Users who have completed the full program, but are still actives: <b>Finished active users</b></li>
                  </ul>
                </div>
              </div>


              <div className="pb-5">
                <div>
                  Lastly, the dashboard displays a table listing all users with their compliance rate and the amount of days they have completed. The compliance rate is the amount of completed days divided by the number of days the user should have completed if they had followed the given schedule.
                </div>
                <div className="">
                  <div className="mt-3 font-semibold">This dashboard has 4 filters:</div>
                  <ul className=" list-disc px-4">
                    <li>Program Name: on this filter, the program being analyzed can be selected. Please note the filter at the top of the Partner Panel’s header will not work on this graph.</li>
                    <li>Passcode: on this filter, one or several passcodes can be filtered, if one wishes to track a specific user.</li>
                    <li>Date Interval: on this filter, the time period being considered can be set. It shows the last 30 days per default.</li>
                    <li>IsTestUser: this filter should be put to FALSE, when wanting to exclude all test users for accurate reporting.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" flex justify-center">
              <iframe
                width="100%"
                height="750"
                src="https://lookerstudio.google.com/embed/reporting/26105a52-6c64-4aa3-a938-a3fec17aed81/page/TC3hD"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Compliance);
