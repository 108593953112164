import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";
import { format } from "date-fns";

// constants
import LocalStrings from "../../languages/LocalStrings";
import Loader from "../Common/Loader";

// actions

// files
import RightAngle from "../../assets/img/icons/angle-right.svg";

const DormantUsers = (props) => {
  const { isLoggedIn, session, partnerUserList, partnerProgramReducer } = props;
  const Navigate = useNavigate();
  const Location = useLocation();

  useEffect(() => {
    // ----- fetch calls
  }, []);

  if (!isLoggedIn) Navigate("/login");
  //console.log("partnerUserList", partnerUserList);
  return (
    <>
      <article className="container px-6 mx-auto grid">
        <div className="grid grid-cols-4 mt-6">
          <div className="col-span-4">
            <div className="pb-5">
              <div>
                This table lists inactive users displaying their passcode, their
                enrollment date, the amount of days they have been inactive as
                well as their last recorded activity.
              </div>
              <div className="">
                <div className="mt-3 font-semibold">
                  This table has 3 filters:
                </div>
                <ul className=" list-disc px-4">
                  <li>
                    Program Name: on this filter, the program being analyzed can
                    be selected. Please note the filter at the top of the
                    Partner Panel’s header will not work on this graph.
                  </li>
                  <li>
                    Days since last activity: on this filter, the amount of days
                    since the last activity can be short-listed.
                  </li>
                  <li>
                    IsTestUser: this filter should be put to FALSE, when wanting
                    to exclude all test users for accurate reporting.
                  </li>
                </ul>
              </div>
            </div>
            <div className=" flex justify-center">
              <iframe
                width="100%"
                height="750"
                src="https://lookerstudio.google.com/embed/reporting/1cd720e7-2d29-4d62-bf25-cd65647b38fc/page/4miiD"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoggedIn: state.isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DormantUsers);
